
import fetch from './fetch.js';

export function login(params) {
  return fetch({
      url: '/info/userInfo/login',
      method: 'post',
      data: params,
      headers: { "Content-Type": "application/json" }
  });
}

export function getTemplateList(params) {
  return fetch({
      url: '/info/templateInfo/page',
      method: 'get',
      params
  });
}

export function addTemplate(params) {
  return fetch({
      url: '/info/templateInfo/add',
      method: 'post',
      data: params,
      headers: { "Content-Type": "application/json" }
  });
}

export function editTemplate(params) {
  return fetch({
      url: '/info/templateInfo/update',
      method: 'post',
      data: params,
      headers: { "Content-Type": "application/json" }
  });
}


export function delTemplate(id) {
  return fetch({
      url: `/info/templateInfo/${id}`,
      method: 'delete',
      data: {id: id}
  });
}


export function unpladFile(params) {
  return fetch({
    url: '/info/file/add',
    method: 'post',
    data: params,
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export function unpladExcel(params) {
  return fetch({
    url: '/info/activityInfo/getExcel', // /info/activityInfo/getExcelForData
    method: 'post',
    data: params,
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export function getTemplateData(params) {
  return fetch({
    url: '/info/templateInfo/get',
    method: 'get',
    params
  });
}


export function getActivityList(params) {
  return fetch({
      url: '/info/activityInfo/page',
      method: 'get',
      params
  });
}

export function delActivity(id) {
  return fetch({
    url: `/info/activityInfo/${id}`,
    method: 'delete',
    data: {id: id}
  });
}

export function addActivity(params) {
  return fetch({
      url: '/info/activityInfo/add',
      method: 'post',
      data: params,
      headers: { "Content-Type": "application/json" }
  });
}

export function getActivityData(params) {
  return fetch({
    url: '/info/activityInfo/get',
    method: 'get',
    params
  });
}

export function getTemplateTypeList(params) {
  return fetch({
    url: '/info/templateInfo/list',
    method: 'get',
    params
  });
}

export function exportExcel(params) {
  return fetch({
    url: '/info/collectInfo/excelData',
    method: 'get',
    params,
    responseType: "blob",
  });
}

export function getUserList(params) {
  return fetch({
    url: '/info/userInfo/page',
    method: 'get',
    params
  });
}

export function getRoleList(params) {
  return fetch({
    url: '/info/userRole/list',
    method: 'get',
    params
  });
}

export function addUser(params) {
  return fetch({
      url: '/info/userInfo/add',
      method: 'post',
      data: params,
      headers: { "Content-Type": "application/json" }
  });
}

export function updateUser(params) {
  return fetch({
      url: '/info/userInfo/update',
      method: 'post',
      data: params,
      headers: { "Content-Type": "application/json" }
  });
}

export function getUser(id) {
  return fetch({
      url: '/info/userInfo/get?id=' + id,
      method: 'get'
  });
}

export function delUser(id) {
  return fetch({
      url: '/info/userInfo/' + id,
      method: 'delete',
      data: {id}
  });
}

export function changePassword(params) {
  return fetch({
    url: '/info/userInfo/retPassword',
    method: 'post',
    data: params,
    headers: { "Content-Type": "application/json" }
  });
}

export function getActivityDetailData(params) {
  return fetch({
    url: '/info/collectInfo/page',
    method: 'post',
    data: params
  });
}

export function delActivityDetailData(params) {
  return fetch({
    url: '/info/collectInfo/delete',
    method: 'post',
    data: params
  });
}

export function addType(params) {
  return fetch({
    url: '/info/dimType/add',
    method: 'post',
    data: params
  });
}

export function delType(id) {
  return fetch({
    url: `/info/dimType/${id}`,
    method: 'delete'
  });
}

export function getTypeList() {
  return fetch({
    url: `/info/dimType/list`,
    method: 'get'
  });
}


