import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/createTemplate',
    name: 'createTemplate',
    component: () => import(/* webpackChunkName: "createTemplate" */ '../views/createTemplate.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/createActivity',
    name: 'createActivity',
    component: () => import(/* webpackChunkName: "createActivity" */ '../views/createActivity.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/activityDetail',
    name: 'activityDetail',
    component: () => import(/* webpackChunkName: "activityDetail" */ '../views/activityDetail.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '*',
    name: 'all',
    redirect: '/login'
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path != '/login') {
    const token = localStorage.getItem('token')
    if (token) {
      next()
    } else {
      next({ path: '/login' });
    }
  } else {
    next()
  }
})


export default router
