import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import router from '../router/index'

const service = axios.create({
  // baseURL: 'http://info.gz2vip.91tunnel.com',
  baseURL: 'https://marketing.yilingdata.com', 
  timeout: 20000
});



service.interceptors.request.use(config => {
  if (config.url != '/info/userInfo/login') { // 登录接口调用不需要设置token
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
  }
  return config;
}, error => {
  Promise.reject(error);
})

const handlerError = (errorMessage) => {
  Message({
    message: errorMessage,
    type: 'error',
    duration: 3 * 1000
  });
  router.push({
    path: '/login'
  })
}

service.interceptors.response.use(
  response => {
    // console.log(response)
    if (!response || response.status != 200) {
      handlerError('网络异常，请重新登录')
      return  Promise.reject('网络异常，请重新登录')
    }
    if (response.data.code == 401 ||response.data.code == 102004 ) {
      handlerError('没有权限')
      return Promise.reject('没有权限')
    } else if (response.data.code == 102001 || response.data.code == 102002) {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      handlerError('token过期，请重新登录!')
      return Promise.reject('token过期，请重新登录!')
    } else if (response.data.status == 500 || response.data.code == 500 ) {
      handlerError('服务器异常!')
      return Promise.reject('服务器异常!')
    }
    return response.data;
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
