<template>
<div class="container">
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <el-form-item label="用户名" prop="userName" >
      <el-input v-model="ruleForm.userName" class="input-style"></el-input>
    </el-form-item>
    <el-form-item label="密码" prop="password">
      <el-input v-model="ruleForm.password"  class="input-style" type="password"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')" class="login-btn input-style" >登录</el-button>
    </el-form-item>
  </el-form>
  <div class="box">
    <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023017542号-1</a>
  </div>
  </div>
</template>
<script>
import {login} from '../api/index.js';
export default {
  data() {
    return {
      ruleForm: {
        userName: '',
        password: ''
      },
      rules: {
        userName: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login({
            account: this.ruleForm.userName,
            password: this.ruleForm.password
          }).then(res => {
            if (res && res.code == 200) {
              localStorage.setItem('userInfo', JSON.stringify(res.data))
              localStorage.setItem('r', res.data.roleType == 'SysAdmin'?1:res.data.roleType == 'CollectAdmin'?2:3)
              localStorage.setItem('token', res.data.token)
              setTimeout(() => {
                this.$router.push({
                  path: '/home'
                })
              }, 1000)
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style lang="scss" scoped>

.container {
  width: 100vw;
  height: 100vh;
  background: url('@/assets/loginBck.jpeg') no-repeat;
  background-size: cover;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  .input-style {
    width: 316px;
  }
  .login-btn {
    // margin-left: 120px;
  }
}

.box {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 10px;
  left: 0;
  color: #eee;
  font-size: 14px;
  a {
    color: #eee;
  }
}

</style>