<template>
  <div id="app">
    <div v-if="$route.path != '/login'">
      <header-component></header-component>
      <div class="AppContainer">
        <side-component></side-component>
        <div class="ContentWrapper">
          <transition name="fade">
            <keep-alive>
              <router-view  v-if="$route.meta.keepAlive"/>
            </keep-alive>
          </transition>
          <transition>
            <router-view v-if="!$route.meta.keepAlive"/>
          </transition>
        </div>
      </div>
      <div class="bottom-wrapper">
        <div class="left-bck"></div>
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023017542号-1</a>
      </div>
    </div>
    <router-view v-else/>
  </div>
</template>
<script>

export default {
  name: 'app',
  components: {
    'header-component': () => import('./views/Header.vue'),
    'side-component': () => import('./views/Side.vue')
  }
}

</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .AppContainer {
    display: flex;
  }
  .ContentWrapper {
    flex: 1;
    box-sizing: border-box;
    height: calc(100vh - 90px);
    overflow-y: auto;
  }
  .bottom-wrapper {
    text-align: center;
    font-size: 12px;
    line-height: 30px;
    color: #aaa;
    position: relative;
    display: flex;
    .left-bck {
      width: 160px;
      height: 30px;
      background-color: rgb(19, 65, 125);
    }
    a {
      color: #aaa;
      flex: 1;
    }
  }
}
</style>
