<template>
  <div class="HomeWrapper">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="活动列表" name="activityList">
        <activity-list v-if="activeName=='activityList'" ref="activityList"></activity-list>
      </el-tab-pane>
      <el-tab-pane label="活动模板" name="activityTemplate" v-if="role!=3">
        <activity-template v-if="activeName=='activityTemplate'" ref="activityTemplate"></activity-template>
      </el-tab-pane>
      <el-tab-pane label="权限设置" name="activityAuth" v-if="role!=3">
        <activity-auth v-if="activeName=='activityAuth'" ref="activityAuth"></activity-auth>
      </el-tab-pane>
      <!-- <el-tab-pane label="数据统计" name="">数据统计</el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    'activity-list': () => import('./activityList.vue'),
    'activity-template': () => import('./activityTemplate.vue'),
    'activity-auth': () => import('./activityAuth.vue'),
  },
  data() {
    return {
      activeName: 'activityList',
      activityNameArr: ['activityList', 'activityTemplate', 'activityAuth'],
      role: 3
    };
  }, 
  created() {
    this.role = +localStorage.getItem('r')
  },
  methods: {
    handleClick(tab, event) {
      if (this.$refs[this.activityNameArr[event - 1]]) {
        this.$refs[this.activityNameArr[event - 1]].getList();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.HomeWrapper {
  padding: 20px;
}
</style>
